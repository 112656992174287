import dynamic from 'next/dynamic';

import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { useRecoilValue } from 'recoil';

import {
  DESKTOP_MEDIA,
  TABLET_MEDIA,
  TABLET_WIDTH,
} from '@common/constants/MEDIAS';
import { css } from '@emotion/react';
import { MainPageResponse } from '@type/api/base/mainpage';

import { palette } from '@lib/styles/palette';

import SwipeEntryBanners from '@components/Home/Banner/SwipeEntryBanners';
import HomeProductsList from '@components/Home/HomeProductList';
import SpaceLayout from '@components/web/Layout/SpaceLayout';
import RankKeyword from '@components/web/RankKeyword/RankKeyword';

import { mainData } from '@recoils/main/atom';

const DynamicHomeProductsList = dynamic(
  () => import('@components/Home/HomeProductList'),
  { ssr: false },
);
const DynamicRecommendedProductsList = dynamic(
  () => import('@components/Home/HomeProductList'),
  { ssr: false },
);

const Home = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const main = useRecoilValue(mainData);

  const refBanner = useRef<HTMLDivElement>(null);

  const { banners, bannerRollingPeriod, coolDeal, video, recommend } =
    main as MainPageResponse;

  const [coolDealMainTitle, coolDealSubTitle] = coolDeal?.mainTitle?.split(
    '\n',
  ) ?? ['', ''];
  const [videoMainTitle, vidoeSubTitle] = video?.mainTitle?.split('\n') ?? [
    '',
    '',
  ];

  return (
    <div css={homeContainer}>
      <div ref={refBanner}>
        <SwipeEntryBanners
          banners={banners}
          rollingSpeed={bannerRollingPeriod}
        />
      </div>
      <div css={rankWrap}>
        <SpaceLayout>
          <RankKeyword />
        </SpaceLayout>
      </div>
      <SpaceLayout>
        <main css={wrap}>
          <section css={popularPrdsStyle}>
            <HomeProductsList
              title={coolDealMainTitle}
              subTitle={coolDealSubTitle}
              products={coolDeal?.items || []}
              detailUrl="product/list?type=cool"
            />
          </section>
          <section css={videoPrdsStyle}>
            <HomeProductsList
              title={videoMainTitle}
              subTitle={vidoeSubTitle}
              productType="VIDEO"
              products={video?.items || []}
              detailUrl="product/list?type=video"
            />
          </section>
          <section ref={ref} css={recommendedPrdsStyle}>
            {inView && (
              <DynamicRecommendedProductsList
                title="중고나라 회원님을"
                subTitle="위한 추천상품!"
                listType="LIST"
                products={recommend?.items || []}
              />
            )}
          </section>
        </main>
      </SpaceLayout>
    </div>
  );
};

const homeContainer = css`
  overflow-x: hidden;
`;

const wrap = css`
  width: 100%;
  margin: 0 auto;
  padding: 44px 0 56px 0;

  > section:not(:first-of-type) {
    margin-top: 70px;
  }

  @media (max-width: ${TABLET_WIDTH}) {
    > section:not(:first-of-type) {
      margin-top: 40px;
    }
  }
`;
const rankWrap = css`
  display: none;

  @media (max-width: ${TABLET_WIDTH}) {
    min-height: 56px;
    display: block;
    border-top: 1px solid ${palette.gray0};
    border-bottom: 4px solid ${palette.gray0};
  }
`;

const popularPrdsStyle = css`
  min-height: 395px;
  ${DESKTOP_MEDIA} {
    min-height: 535px;
  }
`;

const videoPrdsStyle = css`
  min-height: 321px;
  ${DESKTOP_MEDIA} {
    min-height: 497px;
  }
`;

const recommendedPrdsStyle = css`
  min-height: 1513px;
  ${DESKTOP_MEDIA} {
    min-height: 661px;
  }
  ${TABLET_MEDIA} {
    min-height: 794px;
  }
`;

export default Home;
