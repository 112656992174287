import { NextPage } from 'next';

import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-query';

import { useSetRecoilState } from 'recoil';

import { HeaderV2Options } from '@type/web/header';

import useResponsive from '@lib/hook/useResponsive';

import * as BaseAPI from '@api/base/mainpage';

import Home from '@components/Home';
import AppLayout from '@components/web/Layout/AppLayout';

import { mainData as atomMainData } from '@recoils/main/atom';

const HomePage: NextPage = () => {
  const { data: mainData } = useQuery('mainData', () => BaseAPI.getMainPage(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const setMainData = useSetRecoilState(atomMainData);

  if (mainData !== undefined) {
    setMainData(mainData.data);
  }

  const headerOptions: HeaderV2Options = {
    isFixed: {
      pc: false,
      tablet: false,
    },
    showAppDownloadBtn: true,
    showNavigationBar: ['mobile', 'tablet', 'desktop'],
  };

  return (
    <Fragment>
      <AppLayout headerOptions={headerOptions}>
        <Home />
      </AppLayout>
    </Fragment>
  );
};

export default HomePage;
