import { ReactElement } from 'react';

import { MOBILE_WIDTH, TABLET_WIDTH } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';

import { palette } from '@lib/styles/palette';

export default function SkeletonBanner(): ReactElement {
  return (
    <div css={skeletonBannerStyle}>
      <div className="inner">
        <div className="animationBar"></div>
      </div>
    </div>
  );
}

const skeletonBannerStyle = css`
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: auto;
  background-color: ${palette.gray0};

  .inner {
    margin: auto;
    overflow: hidden;
    position: relative;

    // 데스크탑
    @media (min-width: ${TABLET_WIDTH}) {
      max-width: 1024px;
      height: 416px;
    }

    // 태블릿
    @media (max-width: ${TABLET_WIDTH}) {
      padding-top: 40%;
      margin-top: 100px;
    }

    // 모바일
    @media (max-width: ${MOBILE_WIDTH}) {
      padding-top: 60%;
      margin-top: 100px;
    }
  }

  @keyframes loading {
    0% {
      transform: translateX(0);
    }
    50%,
    100% {
      transform: translateX(100%);
    }
  }

  .animationBar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(245, 245, 245, 1) 0%,
      #ffffffae 30%,
      rgba(245, 245, 245, 1) 60%
    );
    animation: loading 1.5s infinite linear;
  }
`;
