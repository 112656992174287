/* eslint-disable import/prefer-default-export */

export const checkIsValidResourceUrl = (url: string) =>
  url?.startsWith('http') || url?.startsWith('/static/');

export const getValidResourceUrl = (
  src: string,
  imgType: 'image' | 'wikiImage' = 'image',
  sizeType: 'normal' | 'thumbnail' = 'normal',
): string => {
  const urlPrefix =
    imgType === 'wikiImage'
      ? process.env.NEXT_PUBLIC_IMG_WIKI_URL
      : process.env.NEXT_PUBLIC_IMG_URL;
  const urlSuffix = sizeType === 'thumbnail' ? '?impolicy=thumb' : '';

  return checkIsValidResourceUrl(src)
    ? `${src}${urlSuffix}`
    : `${urlPrefix}${src}${urlSuffix}`;
};

export const handleImgError = (defaultImg: string) => {
  return (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = defaultImg;
  };
};

export const checkCategoryDepth = (categorySeq: number): number => {
  if (categorySeq > 23 && categorySeq < 185) return 2;
  if (categorySeq > 185) return 3;

  return 1;
};

export const ellipsisWord = (keyword: string): string => {
  if (keyword.length <= 7) return keyword;
  const sliceString = keyword.slice(0, 7);
  const result = sliceString.concat('...');

  return result;
};

export const isSameObjKeys = (
  firstObj: { [key: string]: any },
  secondObj: { [key: string]: any },
): boolean => {
  const firstKeys = Object.keys(firstObj).sort();
  const secondKeys = Object.keys(secondObj).sort();

  return JSON.stringify(firstKeys) === JSON.stringify(secondKeys);
};
