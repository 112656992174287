import React, { ReactElement, useRef, useCallback, useState } from 'react';
import Slider from 'react-slick';

import { Carousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';

import { TABLET_WIDTH, MOBILE_WIDTH } from '@common/constants/MEDIAS';
import getIsDeepLink from '@common/deepLinking/getIsDeepLink';
import { css } from '@emotion/react';
import { Banner } from '@type/api/base/mainpage';

import { palette } from '@lib/styles/palette';

import { putBannerHit } from '@api/base/mainpage';

import Icon from '@components/common/Icon/Icon';
import SkeletonBanner from '@components/common/Skeleton/Banner/SkeletonBanner';
import { SkeletonImage } from '@components/common/Skeleton/SkeletonImage';
import SpaceLayout from '@components/web/Layout/SpaceLayout';

interface SwipeEntryBannersProps {
  banners: Array<Banner>;
  rollingSpeed: number;
}

function SwipeEntryBanners({
  banners,
  rollingSpeed,
}: SwipeEntryBannersProps): ReactElement {
  const carousel = useRef<CarouselRef>(null);
  const bannersWithAllLinks = banners.filter((v) => {
    // TODO 출첵이벤트 next 버전에 추가되면 다시 배너에 노출시키기
    const isNotAttendEvent = v?.bannerSeq !== 11709;
    const isNotMoWebLink = !/^https{0,1}:\/\/m.joongna.com/.test(v?.webUrlLink);
    const hasValidWebUrlLink =
      !!v?.webUrlLink &&
      !/^\//.test(v?.webUrlLink) &&
      !getIsDeepLink(v?.webUrlLink);
    const hasWebBannerUrl = !!v?.v3BannerUrl && v?.v3BannerUrl !== '';
    const hasDeepLink = !!v?.deepLink;
    return (
      isNotAttendEvent &&
      isNotMoWebLink &&
      hasValidWebUrlLink &&
      hasDeepLink &&
      hasWebBannerUrl
    );
  });

  const setNumberingPaging = useCallback((dots: []) => {
    const findActive = dots.find(
      (dot: { key: string; props: { className: string } }) =>
        dot.props.className === 'slick-active',
    ) || { key: '0' };

    return (
      <div>
        <ul css={paging}>
          {dots.map((dot: { key: string; props: { className: string } }) => (
            <li
              key={dot.key}
              className={`pageDot ${dot.props.className && 'active'}`}
            />
          ))}
        </ul>
        <div css={numbering} className="slick-numbers">
          <SpaceLayout>
            <span className="active">{Number(findActive.key) + 1}</span>/
            {dots.length}
          </SpaceLayout>
        </div>
      </div>
    );
  }, []);

  const onClickBanner = async (linkInfo: {
    webUrlLink: string;
    deepLink: string;
    bannerSeq: number;
  }) => {
    const { bannerSeq, webUrlLink } = linkInfo;
    window.open(webUrlLink)?.focus();

    try {
      await putBannerHit(bannerSeq);
    } catch (err) {
      console.error(
        'An error occurred while counting banner clicks. (in putWhichBannerClickOrNot function)',
        err,
      );
    }
  };

  const slickRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const slickSetting = {
    slidesToShow: 3,
    swipeToSlide: true,
    dots: true,
    responsive: [
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false),
  };
  const handleNext = () => (slickRef.current as any)?.slickNext();
  const handlePrev = () => (slickRef.current as any)?.slickPrev();

  if (!bannersWithAllLinks.length) return <SkeletonBanner />;

  return (
    <div css={wrap}>
      <Slider {...slickSetting} ref={slickRef}>
        {bannersWithAllLinks.map((banner: Banner, idx) => {
          const {
            bannerTitle,
            bannerSeq,
            backgroundColor,
            v3BannerUrl,
            webUrlLink,
            deepLink,
            v3TopTitle,
            v3BottomTitle,
            v3SubTitle,
          } = banner;

          return (
            <div key={bannerSeq} css={bannerItem(backgroundColor)}>
              <div
                className="inner"
                onClick={(e) => {
                  if (dragging) {
                    e.stopPropagation();
                    return;
                  }
                  onClickBanner({ webUrlLink, deepLink, bannerSeq });
                }}
              >
                <SkeletonImage
                  src={
                    v3BannerUrl.startsWith('/')
                      ? `https://img2.joongna.com${v3BannerUrl}`
                      : v3BannerUrl
                  }
                  alt={`${bannerTitle}_배너`}
                  imgStyle={bannerImgStyle}
                  backgroundColor="transpalent"
                />
                <p className="banner-inner-text">
                  <div className="banner-inner_top">{v3TopTitle}</div>
                  <div className="banner-inner_bottom">{v3BottomTitle}</div>
                  <div className="banner-inner_sub">{v3SubTitle}</div>
                </p>
              </div>
            </div>
          );
        })}
      </Slider>
      <div css={pcOnlyStyle}>
        <button
          type="button"
          aria-label="arrowLeft"
          css={controllBtn('left')}
          onClick={handlePrev}
        >
          <Icon icon="arrowLeft" size={28} stroke={palette.gray9} />
        </button>
        <button
          type="button"
          aria-label="arrowRight"
          css={controllBtn('right')}
          onClick={handleNext}
        >
          <Icon icon="arrowRight" size={28} stroke={palette.gray9} />
        </button>
      </div>
    </div>
  );
}

const wrap = css`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  .slick-slider .slick-dots {
    margin-left: 0;
    margin-right: 0;
  }
  .slick-slide {
    padding: 12px;
    @media screen and (max-width: 740px) {
      padding: 0;
    }
  }
  .slick-dots {
    position: relative;
    bottom: 0;
    margin: 32px 0 56px;
    @media screen and (max-width: 740px) {
      margin-top: 8px;
    }
    li {
      transition: all 0.3s linear;
      background-color: ${palette.gray9};
      opacity: 0.3;
      border-radius: 2px;
      width: 4px;
      height: 4px;
      padding: 0;
      margin: 0 2px;
      button {
        display: none;
      }
    }
    .slick-active {
      background-color: ${palette.gray9};
      opacity: 1;
      width: 24px;
      border-radius: 3px;
    }
  }
`;

const bannerItem = (colorChip: string) => css`
  width: 100%;
  background: ${RegExp(/([0-9a-f]{6})$/i).test(colorChip.replace('#', ''))
    ? `#${colorChip.replace('#', '')}`
    : 'white'};

  border-radius: 12px;
  overflow: hidden;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.15));

  @media screen and (max-width: 740px) {
    border-radius: 0;
    filter: none;
  }

  .inner {
    margin: auto;
    overflow: hidden;
    position: relative;
    padding-top: 100%;
  }
  .banner-inner-text {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    justify-content: flex-end;
    color: white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.12);

    .banner-inner_top,
    .banner-inner_bottom {
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
    }
    .banner-inner_sub {
      margin-top: 14px;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
    }
  }
`;

const bannerImgStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const controllBtn = (position: string) => css`
  width: 40px;
  height: 40px;
  margin: auto;
  background: none;
  position: absolute;
  top: 0;
  bottom: 0;
  ${`${position}`}: -40px;
`;

const paging = css`
  margin-bottom: 16px;

  li.pageDot {
    width: 4px;
    height: 4px;
    margin: 0 2px;
    padding: 0;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    text-align: center;
    text-indent: -999px;
    vertical-align: top;
    transition: all 0.5s;

    &.active {
      width: 24px;
      background: rgba(255, 255, 255, 1);
    }
  }

  @media (max-width: ${TABLET_WIDTH}) {
    display: none;
  }
`;

const numbering = css`
  display: none;

  @media (max-width: ${TABLET_WIDTH}) {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;

    &.slick-numbers {
      width: auto;
      font-weight: 700;
      margin: 0;
      color: rgba(255, 255, 255, 0.6);

      .active {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
`;

const pcOnlyStyle = css`
  @media (max-width: ${TABLET_WIDTH}) {
    display: none;
  }
`;

export default SwipeEntryBanners;
