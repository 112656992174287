import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import {
  searchPriceKeywordSource,
  actionDetailType as actionType,
} from '@common/const';
import {
  RecommendKeywordType,
  SearchPriceProductData,
} from '@type/api/standardPrice/price';
import { ApiResponse } from '@type/web/api';
import { ProductsData, StandardPriceMainResultData } from '@type/web/search';

import { axiosRequest, AxiosDomain } from '@api/config';

dayjs.locale('ko');
dayjs.extend(weekOfYear);

export const dayString = (format = 'YYYY-MM-DD HH:mm:ss') => {
  return dayjs().format(format);
};

const axiosSearch = AxiosDomain.search;

/** 시세조회 추천 키워드 리스트 api */
export const getSearchPriceRecommendKeyword = (): Promise<
  ApiResponse<RecommendKeywordType>
> => {
  const path = `/v25/search/price/average/recommend`;
  return axiosRequest<RecommendKeywordType>(axiosSearch, 'post', path);
};

/** 시세조회 main api */
export const getSearchPriceMainInfo = (
  keyword: string,
  keywordSource: keyof typeof searchPriceKeywordSource,
  osType: 0 | 1 | 2 | 3,
  actionDetailType: {
    [K in keyof typeof actionType]: typeof actionType[K];
  }[keyof typeof actionType],
  transactionId: string,
): Promise<ApiResponse<StandardPriceMainResultData>> => {
  const path = `/v25/search/price/average/main`;

  const body = {
    searchWord: keyword,
    keywordSource,
    osType,
    saleYn: 'SALE_Y',
    actionDetailType: actionDetailType.text,
    searchStartTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    transactionId,
  };

  const headers = {
    referrer: `${window.location.href}`,
  };

  return axiosRequest<StandardPriceMainResultData>(
    axiosSearch,
    'post',
    path,
    body,
    headers,
  );
};

// export const getSearchPriceMainInfo = (
//   keyword: string,
// ): Promise<ApiResponse<ProductsData>> => {
//   const path = `/v25/search/price/average/main`;

//   const body = {
//     keywordSource: null,
//     osType: 2,
//     saleYn: 0,
//     searchStartTime: dayString(),
//     searchWord: keyword,
//     transactionId: '',
//     page: 0,
//     quantity: 4,
//   };
//   return axiosRequest<ProductsData>(axiosSearch, 'post', path, body);
// };

/** 시세조회 product api */
export const getSearchPriceProduct = (
  productSeq: number,
  keywordSource: keyof typeof searchPriceKeywordSource,
  osType: 0 | 1 | 2 | 3,
  actionDetailType: {
    [K in keyof typeof actionType]: typeof actionType[K];
  }[keyof typeof actionType],
  transactionId: string,
): Promise<ApiResponse<SearchPriceProductData>> => {
  const path = `/v25/search/price/average/product`;
  const body = {
    productSeq,
    osType,
    searchStartTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    saleYn: 'SALE_Y',
    transactionId,
    actionDetailType: actionDetailType.text,
  };
  return axiosRequest<SearchPriceProductData>(axiosSearch, 'post', path, body);
};
// export const getSearchPriceProduct = (): Promise<
//   ApiResponse<SearchPriceProductData>
// > => {
//   const path = `/v25/search/price/average/product`;
//   const body = {
//     osType: 2,
//     saleYn: `SALE_N`,
//     searchStartTime: dayString(),
//     productTitle: '아이패드',
//     transactionId: '',
//     actionDetailType: 'MARKET_PRICE_MAIN',
//   };
//   return axiosRequest<SearchPriceProductData>(axiosSearch, 'post', path, body);
// };
