import { MainPageResponse } from '@type/api/base/mainpage';
import { ApiResponse } from '@type/web/api';

import { axiosRequest, AxiosDomain } from '@api/config';

const axiosBoot = AxiosDomain.mainBase;

/** 앱 초기 데이터 조회 */
export const getMainPage = (
  version = 'v3',
): Promise<ApiResponse<MainPageResponse>> => {
  const path = `/mainpage/${version}`;
  return axiosRequest<MainPageResponse>(axiosBoot, 'get', path);
};

/** 배너 클릭수 집계 */
export const putBannerHit = (
  bannerSeq: number,
): Promise<ApiResponse<MainPageResponse>> => {
  const path = `/mainpage/banner/hit/${bannerSeq}`;
  return axiosRequest<MainPageResponse>(axiosBoot, 'put', path);
};

export default { getMainPage, putBannerHit };
