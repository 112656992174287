import React, { forwardRef, ReactElement, ForwardedRef } from 'react';

import { css, SerializedStyles } from '@emotion/react';

interface TextEllipsisSpanPropsType {
  [key: string]: any;
  lineCount?: number;
  lineHeight?: number;
  children: any;
  style?: SerializedStyles; // css emotion style string
}

function PureTextEllipsisSpan(
  {
    lineHeight = 17,
    lineCount = 2,
    children,
    style,
    ...props
  }: TextEllipsisSpanPropsType,
  ref: ForwardedRef<HTMLSpanElement>,
): ReactElement {
  const spanStyle = css`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${lineCount};
    -webkit-box-orient: vertical;
    line-height: ${lineHeight}px;
    max-height: ${lineCount * lineHeight}px;
  `;

  return (
    <span ref={ref} css={[spanStyle, style]} {...props}>
      {children}
    </span>
  );
}

const TextEllipsisSpan = forwardRef<HTMLSpanElement, TextEllipsisSpanPropsType>(
  PureTextEllipsisSpan,
);
export default TextEllipsisSpan;
