import dayjs from 'dayjs';
import { atom } from 'recoil';

import { MainPageResponse } from '@type/api/base/mainpage';

export const mainData = atom<MainPageResponse>({
  key: 'MAIN/MAIN_DATA',
  default: {
    banners: [],
    bannerRollingPeriod: 5000,

    coolDeal: null,
    video: null,
    recommend: {
      transactionId: '',
      abTestType: null,
      size: 5,
      totalSize: 5,
      items: [],
    },
    popularWord: {
      exhibitionName: '지금 많이 찾는 검색어',
      maxWordLength: 10,
      reloadDateTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      wordItems: [],
    },
    quickMenuList: [],
  },
});

export default { mainData };
