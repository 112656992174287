/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactElement, useMemo } from 'react';

import { TABLET_WIDTH } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  ProductsType,
  ProductListType,
  ProductItemType,
} from '@type/web/product';

import { palette } from '@lib/styles/palette';

import Button from '@components/common/Button/Button';
import Icon from '@components/common/Icon';
import ProductList from '@components/web/ProductList';

interface HomeProductsListProps {
  title: string;
  subTitle?: string;
  productType?: keyof ProductItemType;
  products: Partial<ProductsType>[];
  detailUrl?: string;
  listType?: keyof ProductListType;
  onClickMoreBtn?: () => void;
  className?: string;
  priority?: boolean;
}

function HomeProductsList({
  title,
  subTitle = '',
  productType,
  products,
  listType,
  detailUrl = '',
  onClickMoreBtn = () => {},
  className = '',
  priority,
}: HomeProductsListProps): ReactElement {
  const pcProductList = useMemo(
    () => (detailUrl ? products.slice(0, 5) : products),
    [products, detailUrl],
  );

  return (
    <div className={className}>
      <div css={titleWrap}>
        <div>
          <p className="main">{title}</p>
          <p className="sub">{subTitle}</p>
        </div>
        {detailUrl && products.length !== 0 && (
          <TabletButton
            to={detailUrl}
            onClick={onClickMoreBtn}
            type="link"
            rightIcon={
              <Icon icon="arrowRight" stroke={palette.gray6} size={12} />
            }
          >
            더 보기
          </TabletButton>
        )}
      </div>
      <StyledProductList
        forPC
        type={listType || 'GALLERY'}
        length={5}
        products={pcProductList}
        productType={productType}
        name={title}
      />
      {detailUrl && products.length !== 0 && (
        <div css={btnWrap}>
          <PCButton
            to={detailUrl}
            onClick={onClickMoreBtn}
            type="light"
            width="136px"
            color={palette.gray6}
          >
            더 보기
          </PCButton>
        </div>
      )}
      <StyledProductList
        forPC={false}
        type={listType || 'SWIPER'}
        products={products}
        productType={productType}
        name={title}
        priority={priority}
      />
    </div>
  );
}

const titleWrap = css`
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;

  .main {
    margin-bottom: 3px;
  }

  @media (max-width: ${TABLET_WIDTH}) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

const StyledProductList = styled(ProductList)(
  ({ forPC, type }: { forPC: boolean; type?: string }) => css`
    display: ${forPC ? 'flex' : 'none'};

    @media (max-width: ${TABLET_WIDTH}) {
      display: ${forPC ? 'none' : type === 'LIST' ? 'flex' : 'block'};
    }
  `,
);

const PCButton = styled(Button)`
  background-color: ${palette.white};
  border-color: ${palette.gray2};

  &:hover {
    border-color: ${palette.gray2};
    background-color: ${palette.gray0};
  }

  @media (max-width: ${TABLET_WIDTH}) {
    display: none;
  }
`;

const TabletButton = styled(Button)`
  display: none;

  @media (max-width: ${TABLET_WIDTH}) {
    display: inline;
    padding: 0;
    font-size: 12px;
    color: ${palette.gray6};
    gap: 0;

    :hover {
      color: ${palette.gray6};
    }
    svg {
      margin-left: 6px;
    }
  }
`;

const btnWrap = css`
  display: flex;
  justify-content: center;
  margin-top: 28px;

  @media (max-width: ${TABLET_WIDTH}) {
    display: unset;
    margin-top: 0;
  }
`;

export default HomeProductsList;
